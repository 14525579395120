import PropTypes from "prop-types"
import React from "react"

const Form = () => {
  const handleClick = (e) =>  {
    var label = document.querySelector('.second');
    var input = document.querySelectorAll('.input3-select');
    label.checked = true;
    if(label.checked) {
      input.forEach(input => {
        input.style.height = '40px';
        input.style.zIndex = '1';
        input.style.marginBottom = '25px';
      });
    } 
  } 

  const handleClick1 = (e) =>  {
    var label = document.querySelector('.first');
    var input = document.querySelectorAll('.input3-select');
    label.checked = true;
    if(label.checked) {
      input.forEach(input => {
        input.style.height = '0';
        input.style.zIndex = '-1';
        input.style.marginBottom = '0';
      });
    } 
  }

  return(
  <div className="wrap-contact3">
    <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" data-netlify="true" className="contact3-form validate-form">
    <p class="noShow">
    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
  </p>
      <span className="contact3-form-title">Contact Me</span>
      <div className="wrap-contact3-form-radio">
        <div className="contact3-form-radio m-r-42">
          <input
            className="input-radio3"
            id="radio1"
            type="radio"
            name="choice"
            value="say-hi"
            checked="checked"
          />
          <label onClick={handleClick1} className="label-radio3 first" htmlFor="radio1">
            Say Hi
          </label>
        </div>
        <div className="contact3-form-radio">
          <input
            className="input-radio3 second"
            id="radio2"
            type="radio"
            name="choice"
            value="get-quote"
          />
          <label onClick={handleClick} className="label-radio3" htmlFor="radio2">
            Get a Quote
          </label>
        </div>
      </div>
      <div className="wrap-input3 validate-input" data-validate="Name is required">
        <input className="input3" type="text" name="name" placeholder="Your Name" />
        <span className="focus-input3"></span>
      </div>
      <div
        className="wrap-input3 validate-input"
        data-validate="Valid email is required: ex@abc.xyz"
      >
        <input
          className="input3"
          type="text"
          name="email"
          placeholder="Your Email"
        />
        <span className="focus-input3"></span>
      </div>
      <div className="wrap-input3 input3-select">
        <div>
          <select
            className="selection-2 select2-hidden-accessible"
            name="service"
            tabIndex="-1"
            aria-hidden="true"
          >
            <option>Requested Service</option>
            <option>Technical Onsite Audit</option>
            <option>Content Optimisation & Copywriting</option>
            <option>Outreach & PR</option>
            <option>Other</option>
          </select>
          <span
            className="select2 select2-container select2-container--default select2-container--below"
            dir="ltr"
          >
            <span className="selection">
              <span
                className="select2-selection select2-selection--single"
                role="combobox"
                aria-haspopup="true"
                aria-expanded="false"
                aria-controls="expandable"
                tabIndex="0"
                aria-labelledby="select2-service-87-container"
              >
                <span
                  className="select2-selection__rendered"
                  id="select2-service-87-container"
                  title="UI/UX Design"
                >
                  UI/UX Design
                </span>
                <span className="select2-selection__arrow" role="presentation">
                  <b role="presentation"></b>
                </span>
              </span>
            </span>
            <span className="dropdown-wrapper" aria-hidden="true"></span>
          </span>
        </div>
        <span className="focus-input3"></span>
      </div>
      <div className="wrap-input3 input3-select">
        <div>
          <select
            className="selection-2 select2-hidden-accessible"
            name="budget"
            tabIndex="-1"
            aria-hidden="true"
          >
            <option>Estimated Budget</option>
            <option>Under £500</option>
            <option>£500 - £1000</option>
            <option>£1000 - £2000</option>
            <option>Over £2000</option>
          </select>
          <span
            className="select2 select2-container select2-container--default select2-container--below"
            dir="ltr"
          >
            <span className="selection">
              <span
                className="select2-selection select2-selection--single"
                role="combobox"
                aria-haspopup="true"
                aria-expanded="false"
                aria-controls="expandable"
                tabIndex="0"
                aria-labelledby="select2-budget-hd-container"
              >
                <span
                  className="select2-selection__rendered"
                  id="select2-budget-hd-container"
                  title="Budget"
                >
                  Budget
                </span>
                <span className="select2-selection__arrow" role="presentation">
                  <b role="presentation"></b>
                </span>
              </span>
            </span>
            <span className="dropdown-wrapper" aria-hidden="true"></span>
          </span>
        </div>
        <span className="focus-input3"></span>
      </div>
      <div
        className="wrap-input3 validate-input"
        data-validate="Message is required"
      >
        <textarea
          className="input3"
          name="message"
          placeholder="Your Message"
        ></textarea>
        <span className="focus-input3"></span>
      </div>
      <div className="field">
        <div data-netlify-recaptcha="true"></div>
      </div>
      <div className="container-contact3-form-btn">
        <input type="hidden" name="form-name" value="contact" />
        <button type="submit" className="contact3-form-btn">Submit</button>
      </div>
    </form>
  </div>
)
}

Form.propTypes = {
  siteTitle: PropTypes.string,
}

Form.defaultProps = {
  siteTitle: ``,
}

export default Form
