import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Form from "../components/form"

const Contact = () => (
  <Layout>
    <div className="content-wrapper"
    style={{
      height:`100%`
    }}>
      <SEO title="Contact" />
      <div className="form-wrapper"
      style={{
        minHeight: `850px`
      }}>
        <Form/>
      </div>
    </div>
  </Layout>
)

export default Contact
